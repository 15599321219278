import { Form, withFormik, FormikProps } from 'formik';
import React, { useEffect } from 'react';
import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import {
  FormikDatePicker,
  FormikTextField,
  FormikCustomAutocomplete,
} from '../../../../common/components/formik-controls/FormikComponents';
import Typography from 'apollo-react/components/Typography';
import { FormValues, CompProps } from '../../types/CreateProjectFormTypes';
import { CONSTANTS } from '../../../../common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectFieldsData } from '../../slices/CreateProjectSlice';
import { getSponsorsSelector } from '../../selectors/CreateProjectSelectors';
import { schema } from '../../utils/createProjectFormValidator';
import { isEmpty } from 'lodash';
import Protocol from './Protocol';
import TherapeuticAreas from './TherapeuticAreas';
import Objectives from './Objectives';
import Phases from './Phases';
import './style.scss';
import { getActiveuser } from '../../selectors/userDetailsSelector';
import EngagementType from './EngagementType';

const CreateProjectForm = ({
  isSubmitting,
  closeModal,
  touched,
  errors,
  dirty,
  handleFormDirty,
}: CompProps & FormikProps<FormValues>) => {
  const dispatch = useDispatch();
  const sponsors = useSelector(getSponsorsSelector);
  const { userid, tenantid, userName } = useSelector(getActiveuser);

  useEffect(() => {
    dispatch(getProjectFieldsData({ userid, tenantid }));
  }, [dispatch, userid, tenantid]);

  useEffect(() => {
    handleFormDirty(dirty);
  }, [dirty, handleFormDirty]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if ((event.charCode || event.keyCode) === 13) {
      event.preventDefault();
    }
  };

  const isFormInValid = () => {
    if (isEmpty(touched)) {
      return true;
    } else if (!isEmpty(touched) && !isEmpty(errors)) {
      return true;
    }

    return false;
  };

  return (
    <Form noValidate className='createProjectForm' data-testid="createProjectForm" onKeyDown={handleKeyDown}>
      <FormikTextField
        fullWidth
        name='name'
        label={CONSTANTS.LABELS.PROJECT_NAME}
        placeholder='Enter Value'
        required
        helperText=''
        data-testid="projectName"
      />
      <EngagementType />
      <Objectives />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FormikCustomAutocomplete
            name='sponsors'
            className='spacer'
            label={CONSTANTS.LABELS.SPONSORS}
            options={sponsors}
            labelKey='name'
            helperText=''
            required
            data-testid="sponsors"
          />
        </Grid>
        <Grid item xs={4}>
          <FormikDatePicker
            name='dueDate'
            className='dueDatePicker'
            label='Due Date'
            dateFormat='DD-MMM-YYYY'
            placeholder='DD-MMM-YYYY'
            helperText=''
            disablePast={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Protocol />
        </Grid>
        <Grid item xs={4}>
          <Phases />
        </Grid>
      </Grid>
      <FormikTextField
        fullWidth
        name='compounds'
        label={CONSTANTS.LABELS.INTERVENTION}
        placeholder='Enter Value'
        helperText=''
      />
      <TherapeuticAreas />
      <FormikTextField
        fullWidth
        name='opportunityNumber'
        label={CONSTANTS.LABELS.SALESFORCE_OPPORTUNITY_NUMBER}
        placeholder='Enter Value'
        helperText=''
        data-testid="opportunityNumber"
      />
      <div className='readOnlySection'>
        <Typography variant='body2'>
          {CONSTANTS.LABELS.PROJECT_OWNER}
        </Typography>
        <Typography variant='body1' className='readOnly'>
          {userName}
        </Typography>
      </div>

      <div className='action'>
        <Button data-testid="close"  variant={'tertiary' as any}  type='button' onClick={() => closeModal(false)}>
          {CONSTANTS.LABELS.CANCEL}
        </Button>
        <Button data-testid="confirm" variant='primary' type='submit' disabled={isFormInValid() || isSubmitting}>
          {CONSTANTS.LABELS.CREATE}
        </Button>
      </div>
    </Form>
  );
};

const initialValues: FormValues = {
  name: '',
  engagementType: '',
  projectCode: '',
  objective: '',
  sponsors: [],
  dueDate: '',
  protocols: [],
  phases: [],
  compounds: '',
  therapeuticAreas: [],
  otherTherapeuticAreas: [],
  owner: '1',
  viewFormState: false,
  opportunityNumber:''
};

const handleSubmit = (values: any, { props }: any) => {
  props.handleFormSubmit(values);
};

const enhancedForm = withFormik<CompProps, FormValues>({
  validationSchema: schema(),
  validateOnBlur: true,
  mapPropsToValues: () => initialValues,
  handleSubmit,
})(CreateProjectForm);

export default enhancedForm;